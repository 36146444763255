// import React, { useEffect, Suspense } from 'react'
import React, { Suspense, useState, useRef, useEffect } from 'react'
import * as THREE from "three"; 
import Loading from '@src/share-component/loading/loading';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

import { UnsignedByteType, PMREMGenerator } from 'three'
import { Reflector, Stage, Environment, Text, useGLTF } from "@react-three/drei";
import { Canvas, useLoader, useThree, useFrame, extend } from '@react-three/fiber'
// import { Canvas, useLoader, useThree, useFrame, extend } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import HDRI from 'three/examples/textures/equirectangular/pedestrian_overpass_1k.hdr'
import './three.css'
// import SF160 from '@src/asset/sf-160.glb'
import SF160 from '@src/asset/adamHead.glb'
// import SF160 from '@src/asset/sfz-120-2.glb'
//import SF160 from '@src/asset/sfz-blender-compress-glb.glb'

extend({ OrbitControls })

const Model = () => {
  
  const { viewport, camera, gl : { domElement } } = useThree()
  // const { viewport, camera, gl } = useThree()
  // const HDRI = 'https://hdrihaven.com/files/hdris/royal_esplanade_1k.hdr'

  // console.log(gl)
  // gl.outputEncoding = THREE.sRGBEncoding
  // gl.gammaOutput = true;
  // gl.gammaFactor = 2.2;

  
  // const gltf = useLoader(GLTFLoader, SF160)
  const gltf = useGLTF(SF160)

  // console.log(gltf)
  const gltfBox = new THREE.Box3().setFromObject(gltf.scene);
  // console.log( box.min, box.max, box.getSize() );
  console.log(gltfBox.getSize().z)
  const z = gltfBox.getSize().z

  // const dracoLoader = new DRACOLoader();
  // dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/'); // use a full url path

  // const gltf = new GLTFLoader();
  // gltf.setDRACOLoader(dracoLoader);
  // gltf.load(SF160, function(gltf) {
  //   console.log(gltf)
  // })
// 
  const ref = useRef()

  // viewport.set()
  // camera.position.set(0, 2, 3, 10000)
  useFrame((state) => {
    // camera.position.set(0, 2.5, 3.5, 10000)
    // gltf.scene.position.set(0, z/2, 0)
  })
  // gltf.scene.position.set(0, z/2, 0)
  //   // console.log(state)
  //   // const x = (mouse.x * viewport.width) / 2
  //   // const y = (mouse.y * viewport.height) / 2
  //   // ref.current.position.set(x, y, 0)
  //   // ref.current.rotation.set(-y, x, 0)
  //   // viewport.position.x = x
  //   // .position.set(0, -3, 40); // default 0, 0, 50
  //   // camera.lookAt(x, y, -0);
  //   // camera.updateProjectionMatrix();
  //   // viewport.rotation.set(-y, x, 0)

  // })

  // console.log(gltf)

  // const pmremGenerator = new PMREMGenerator(gl)
  // const loader = new RGBELoader()
  // loader.setDataType(UnsignedByteType)
  // pmremGenerator.compileEquirectangularShader()

  // // useEffect(() => {
  //   loader.load(HDRI, texture => {
  //     const envMap = pmremGenerator.fromEquirectangular(texture).texture
  //     gltf.scene.environment = envMap
  //     texture.dispose()
  //     pmremGenerator.dispose()
  //   })
  // }, [gltf.scene, loader, pmremGenerator])

  // gltf.scene.traverse( function( object ) {

  //   console.log(object)
  //   if ( object.isMesh ) {
  //       object.material.color.set( 0xffffff );
  //       // object.material.transparent = true;
  //       // object.material.opacity = 0.5;
  //   }
  // });

  
  // console.log(gltf.scene.position)
  return <React.Fragment>
    <primitive ref = {ref} object = { gltf.scene }/>
    {/* <primitive ref = {ref} object = { gltf.scene } position = {[0, z/2, 0]}/> */}
    {/* <primitive ref = {ref} object = { gltf.scene } position = {[0, 0, 0]}/> */}
      {/* <meshStandardMaterial attach="material" transparent opacity={0.5} /> */}
    <orbitControls args = { [ camera, domElement ] } />
    </React.Fragment>
}


const Box = () => {
  return (
    <mesh>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" transparent opacity={0.5} />
    </mesh>
  )
}
const Three = () => {
  const [clicked, set] = useState(false)


  // const gltf = useLoader(GLTFLoader, SF160);
  // console.log( nodes)
  // const M = <mesh geometry={nodes.Cube.geometry}>
  //   <meshStandardMaterial attach="material" color="lightblue" />
  // </mesh>
  // const loader = new GLTFLoader();
  // const loadedData = await loader.loadAsync('asset/adamhead/adamHead.gltf');

  // const renderer = new THREE.WebGLRenderer( { antialias: true } );
  // const scene = new THREE.Scene();
  // const camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 100 );

  
  // renderer.setClearColor( 0xffffff );
  // renderer.setPixelRatio( window.devicePixelRatio );
  // renderer.setSize( width, height );
  // container.appendChild( renderer.domElement );

      // width : 1000,
      // height : 500
      // const scene = new THREE.Scene();
      // const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
      
      // const renderer = new THREE.WebGLRenderer();
      // renderer.setSize( window.innerWidth, window.innerHeight );
      // document.body.appendChild( renderer.domElement );

      // const geometry = new THREE.BoxGeometry();
			// const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
			// const cube = new THREE.Mesh( geometry, material );
      // scene.add( cube );
      // camera.position.z = 5;

      // const animate = function () {
			// 	requestAnimationFrame( animate );

			// 	cube.rotation.x += 0.01;
			// 	cube.rotation.y += 0.01;

			// 	renderer.render( scene, camera );
			// };

			// animate();
  

  // useEffect(() => {
  //   const scene = new THREE.Scene();
  //   scene.background = new THREE.Color( 0xbfe3dd );


  //   const camera = new THREE.PerspectiveCamera(75, 2, 0.1, 1000);
  //   const renderer = new THREE.WebGLRenderer({
  //     canvas : document.getElementById("three-container")
  //   })

  //   // renderer.setPixelRatio(window.devicePixelRatio);
  //   // renderer.setSize(window.innerWidth, window.innerHeight)
  //   // camera.position.setZ(30)
  //   document.body.appendChild( renderer.domElement );

  //   const loader = new GLTFLoader();
  //   // loader.setDRACOLoader( dracoLoader );
  //   loader.load(adam, function ( gltf ) {

  //     const model = gltf.scene;

  //     console.log(model)
  //     model.position.set( 1, 1, 0 );
  //     model.scale.set( 0.01, 0.01, 0.01 );
  //     scene.add( model );

  //     // mixer = new THREE.AnimationMixer( model );
  //     // mixer.clipAction( gltf.animations[ 0 ] ).play();

  //     // animate();

  //   }, undefined, function ( e ) {


  //     console.error( e );

  //   } );

  //   renderer.render( scene, camera );

  //   // Your code here
  // }, []);

  return (
    <div id = 'three-container'>
      <Loading/>
      {/* <h1>SFZ-120</h1> */}
      {/* <Canvas camera = {{ position: [0, 0, 0] }} shadows style = {{ backgroundColor: "white" }}> */}
      <Canvas shadows style = {{ backgroundColor: "white" }}>
        <fog attach = "fog" args = {['#101010', 10, 50]} />
        <ambientLight color = "white" intensity = {1} position = {[0, 0, 0]}/>
        {/* <spotLight color = "white" intensity = {1} position = {[0, 0, 200]} /> */}
        {/* <directionalLight color = "white" position = {[5, 10, 7.5]}/>
        <directionalLight color = "white" position = {[-5, 10, 7.5]}/> */}
        {/* <pointLight color = "white" intensity = {5} position = {[0, 0, 0]} /> */}
        <hemisphereLight/>
        <Text color = "black" fontSize = {5} anchorX = "center" anchorY = "bottom" position = {[0, -1.2, -10]}>
          SFZ-120
        </Text>
        {/* 

        <spotLight intensity = {5} position = {[5, 5, 10]} />
        <spotLight intensity = {5} position = {[-10, -10, 10]} />
        <spotLight intensity = {5} position = {[20, 20, 10]} />
        <spotLight intensity = {5} position = {[5, -5, 10]} />
        <spotLight intensity = {5} position = {[20, 20, 20]} />
        <spotLight intensity = {5} position = {[5, -5, 100]} />
        <spotLight intensity = {5} position = {[5, 5, 100]} />
        <pointLight intensity = {5} position = {[-10, 10, 50]} /> */}
        <Suspense fallback = { <Box /> }>
        <Environment preset = "sunset"/>
        <Stage environment = {null} intensity = {1} contactShadowOpacity = {1} shadowBias = {-0.0015}>
          <Model/>
        </Stage>
          {/* <Box/> */}
        </Suspense>
      </Canvas>
      {/* {!clicked && <button id = 'three-button' onClick={() => set(true)}>Load duck w/ 1s delay</button>} */}
      </div>
  )

}

export default Three
